import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import _ from "lodash";

import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialIcons from "../components/socialIcons"

const windowGlobal = typeof window !== 'undefined' && window;
export const query = graphql`
query ($pageId: String!){
  markdownRemark (id: {eq: $pageId}) {
    html,
    frontmatter{
      title
      slug
      subtitle
      featuredImage {
        childImageSharp {
          fluid(maxWidth: 530, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`

const SimplePage = ({data : { markdownRemark : page}}) => (
  <Layout>
    <SEO title={page.frontmatter.title} />
    <div className="container">
      <section className="section">
        <div className="columns">
          <div className="column is-half-desktop is-full-mobile">
            <figure className="image">
              <Img fluid={ page.frontmatter.featuredImage.childImageSharp.fluid }/>
            </figure>
          </div>
          <div className="column is-half-desktop is-full-mobile">
            <h1 className="title">{ page.frontmatter.title }</h1>
            <h2 className="subtitle is-uppercase">{ page.frontmatter.subtitle }</h2>
            <div className="content" dangerouslySetInnerHTML={{__html: page.html}} />
            <SocialIcons currentPageUrl={ !_.isEmpty(windowGlobal) ? windowGlobal.location.href : ''}/>
          </div>
        </div>
      </section>
    </div>
    
  </Layout>
)

export default SimplePage
